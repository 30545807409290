import React from 'react'
import { Link } from 'gatsby'
// import styled from 'styled-components'
import Layout from '../components/Layout'
import Heading from '../components/Heading'
import ExternalLink from '../components/ExternalLink'

const frontmatter = {
  title: `Welcome`,
}

const IndexPage = () => (
  <Layout>
    <Heading frontmatter={frontmatter} />
    <h2>What is ecommerce automation?</h2>
    <p>
      A basic definition of ecommerce automation is implementing any tool,
      process, or workflow that allows repetitive tasks to be handled
      automatically with little or no human involvement. The tasks are often
      error prone when done manually, and their impact can typically be measured
      as opportunity costs - in other words. what other valuable things could a
      merchant be doing to grow and sustain their business while letting
      automations handle the repetitive tasks more accurately and efficiently?
    </p>
    <p>
      In promoting their Flow offering, Shopify themselves published a fairly
      comprehensive explanation of{' '}
      <ExternalLink href="https://www.shopify.com/enterprise/what-is-ecommerce-automation">
        ecommerce automation
      </ExternalLink>{' '}
      and the benefits that can be obtained from its practices. While Flow is
      currently only available on the Shopify Advanced and Plus plans, merchants
      on any plan level can have automations developed for them using the
      comprehensive Shopify API.
    </p>

    <p>
      Having custom developed automations sounds expensive. In addition to the
      costs to define, develop, and deploy these automatons, there is the
      ongoing maintenance and hosting costs of the automation code, which
      typically will be passed onto the merchant. While many of these costs
      should be offset by the time savings from the foregone collective manual
      work, once a merchant wants to begin automating more and more processes,
      then these costs can continue to increase linearly with the amount of
      solutions developed. There has to be a better way, and there is...
    </p>
    <h2>Mechanic</h2>
    <p>
      Enter <ExternalLink href="https://mechanic.dev/">Mechanic</ExternalLink>,
      a development and automation platform for Shopify that provides
      significant benefits to both merchants and solution developers alike.
    </p>
    <p>
      Merchants get a platform with an ever increasing library of pre-built
      automations that solve many of the frequent pain points encountered in
      ecommerce in general, and more specifically when there is difficulty in
      adapting their existing processes and systems into the Shopify way of
      managing ecommerce.
    </p>
    <p>
      Developers get an ever-evolving, mature, and highly-stable hosted
      development platform built upon the core foundations of the Shopify
      ecosystem, their extensive APIs and the familiar Liquid scripting
      language.
    </p>
    <h2>Automate all the things!</h2>
    <p>
      Whether you are a solopreneur, a mid-size merchant, or a high-volume Plus
      shop, Tekhaus can assist you with automating the ecommerce efficiency
      roadblocks away. <Link to="/contact">Contact Tekhaus</Link> to learn how.
    </p>
  </Layout>
)

export default IndexPage
